import {
    BaseClientConfig,
    CollectionEvent,
    DataLayerEventName,
    generateViewItemCardInfo,
    Product,
    useFeature,
} from '@news-mono/web-common'
import fetch from 'node-fetch'
import { useRef, useState } from 'react'
import { useQueries, UseQueryResult } from 'react-query'
import {
    getNewsTickerContext,
    getNewsTickerItems,
    NewsTickerItem,
} from './helpers'
import { isEqual } from 'lodash'

export const useNewsTicker = (
    config: BaseClientConfig,
    onEvent?: (event: CollectionEvent) => void,
) => {
    const latestEditionThresholdInHour = 24
    const maxTotalOfNewsTickerItem = 5

    const newsTickerSourcesRef = useRef<UseQueryResult[]>([])

    const [newsTickerItems, setNewsTickerItems] = useState<NewsTickerItem[]>([])

    const addEditionToNewsTickerEnabled = useFeature(
        'add-edition-to-news-ticker',
    )
    const isNewsTickerDefaultToTop5Enabled = useFeature(
        'news-ticker-default-to-top-five',
    )

    const newsTickerSources = useQueries([
        {
            queryKey: ['news-ticker'],
            queryFn: () => fetchCurationById(config, 'news-ticker'),
        },
        {
            queryKey: ['top-stories'],
            queryFn: () => fetchCurationById(config, 'top-stories'),
            enabled: !!isNewsTickerDefaultToTop5Enabled,
        },
        {
            queryKey: ['edition'],
            queryFn: () => fetchEdition(config),
            enabled: !!addEditionToNewsTickerEnabled,
        },
    ])

    if (!isEqual(newsTickerSourcesRef.current, newsTickerSources)) {
        const mappedNewsTickerItems = getNewsTickerItems(
            latestEditionThresholdInHour,
            maxTotalOfNewsTickerItem,
            newsTickerSources,
        )

        if (mappedNewsTickerItems) {
            const { newsTickerContext, newsTickerTextCollection } =
                getNewsTickerContext(mappedNewsTickerItems)

            if (onEvent) {
                onEvent({
                    context: newsTickerContext,
                    type: DataLayerEventName.viewItemList,
                    originator: 'TNNewsTicker',
                    payload: {
                        cardInformation: generateViewItemCardInfo(
                            newsTickerTextCollection,
                            Product.TheNightly,
                        ),
                    },
                })
            }

            setNewsTickerItems(mappedNewsTickerItems)
        }
    }

    newsTickerSourcesRef.current = newsTickerSources

    return newsTickerItems
}

export const fetchCurationById = async (
    config: BaseClientConfig,
    curationId: string,
) => {
    const url = `${config.contentApi}/v4/curation/${curationId}`
    const requestConfig = getRequestConfig(config)

    return await fetch(url, requestConfig).then((response) => response.json())
}

const fetchEdition = async (config: BaseClientConfig) => {
    const url = `${config.contentApi}/v4/edition`
    const requestConfig = getRequestConfig(config)

    return await fetch(url, requestConfig).then((response) => response.json())
}

const getRequestConfig = (config: BaseClientConfig) => {
    return {
        headers: {
            caller: config.apiCallerHeader,
        },
    }
}
