import { NotificationCentreListSection } from '../types'
import {
    CollectionEvent,
    DataLayerEventName,
    generateViewItemCardInfo,
    getProductName,
} from '@news-mono/web-common'
import {
    getFlatNotificationList,
    getNotificationCentreEventContext,
} from './helpers'
import { useCallback } from 'react'
import { useProduct } from '@news-mono/component-library'
import { NotificationCentreItemV4DTO } from '@west-australian-newspapers/publication-types'

interface NotificationCentreEventsProps {
    notificationSections: NotificationCentreListSection[]
    onEvent: (event: CollectionEvent) => void
}

export const useNotificationCentreEvents = ({
    notificationSections,
    onEvent,
}: NotificationCentreEventsProps) => {
    const flatNotificationList = getFlatNotificationList(notificationSections)
    const notificationCentreContext = getNotificationCentreEventContext(
        flatNotificationList.length,
    )

    const product = useProduct()

    const onViewItemListEvent = useCallback(() => {
        onEvent({
            context: notificationCentreContext,
            type: DataLayerEventName.viewItemList,
            originator: 'NotificationCentre',
            payload: {
                cardInformation: generateViewItemCardInfo(
                    flatNotificationList,
                    product,
                ),
            },
        })
    }, [flatNotificationList, notificationCentreContext, onEvent, product])

    const onViewItemEvent = useCallback(
        (index: number, notification: NotificationCentreItemV4DTO) => {
            onEvent({
                context: notificationCentreContext,
                type: DataLayerEventName.selectItemEvent,
                originator: 'NotificationCentre',
                payload: {
                    index,
                    item_brand: getProductName(product),
                    item_category: 'notification-centre',
                    item_name: notification.publicationId,
                    price: 0,
                    link_text: notification.body,
                    link_url: notification.url,
                },
            })
        },
        [notificationCentreContext, onEvent, product],
    )

    return {
        onViewItemListEvent,
        onViewItemEvent,
    }
}
