import React, {
    FC,
    useCallback,
    useContext,
    useEffect,
    useLayoutEffect,
    useState,
} from 'react'
import {
    StyledAnimatedTextContainer,
    StyledArrowLink,
    StyledFloatyArrow,
    StyledFixedArrow,
    StyledClassification,
    StyledContainer,
    StyledText,
    StyledTextContainer,
} from './NewsTicker.styled'
import { ArrowNarrowRightIcon } from './ArrowNarrowRightIcon'
import { EditorialType } from '../../templates'
import { StyledLiveClassificationContainer } from '../../cards/LandscapeNightly/LandscapeNightly.styled'
import { IconLiveTN } from '../../icons'
import {
    AppState,
    CollectionEvent,
    ConfigurationContext,
    DataLayerEventName,
    isPuzzlePage,
} from '@news-mono/web-common'
import { useNewsTicker } from './useNewsTicker'
import { getNewsTickerContext } from './helpers'
import { CollectionContext } from '../../tracking'
import { QueryClient, QueryClientProvider } from 'react-query'
import { useSelector } from 'react-redux'
import { useLocation } from 'react-router'

interface NewsTickerProps {
    onEvent: (event: CollectionEvent) => void
}

export interface AnimationKeyframePercentage {
    fadeInEnd: number
    scrollLeftStart: number
    scrollLeftEnd: number
    fadeOutStart: number
}

const getTotalTimeForAnimationSequence = (
    fadeDurationInSeconds: number,
    pauseBeforeFadeOutInSeconds: number,
    fullWidth?: number,
    speedInpixelsPerSeconds?: number,
    pauseBeforeScrollLeftInSeconds?: number,
): number => {
    const scrollLeftDurationInSeconds =
        fullWidth && speedInpixelsPerSeconds
            ? Math.ceil(fullWidth / speedInpixelsPerSeconds)
            : 0

    let animationTotalTime =
        scrollLeftDurationInSeconds +
        fadeDurationInSeconds +
        fadeDurationInSeconds + // x2 due to fade in and out
        pauseBeforeFadeOutInSeconds

    if (pauseBeforeScrollLeftInSeconds) {
        animationTotalTime = animationTotalTime + pauseBeforeScrollLeftInSeconds
    }

    return animationTotalTime
}

const getAnimationKeyframes = (
    animationTotalTime: number,
    fadeDurationInSeconds: number,
    pauseBeforeScrollLeftInSeconds: number,
    pauseBeforeFadeOutInSeconds: number,
): AnimationKeyframePercentage => {
    // '100' represents the total length of the keyframe, which is 100%
    const fadeInEnd = (fadeDurationInSeconds * 100) / animationTotalTime
    const fadeOutStart = 100 - fadeInEnd

    const scrollLeftStart =
        pauseBeforeScrollLeftInSeconds > 0
            ? fadeInEnd +
              (pauseBeforeScrollLeftInSeconds * 100) / animationTotalTime
            : 0

    const scrollLeftEnd =
        100 -
        fadeInEnd -
        (pauseBeforeFadeOutInSeconds * 100) / animationTotalTime

    return {
        fadeInEnd: fadeInEnd,
        fadeOutStart: fadeOutStart,
        scrollLeftStart: scrollLeftStart,
        scrollLeftEnd: scrollLeftEnd,
    }
}

const InternalNewsTicker: FC<NewsTickerProps> = ({ onEvent }) => {
    // animation values
    const speedInPixelsPerSeconds = 40
    const fadeDurationInSeconds = 0.3
    const pauseBeforeScrollLeftInSeconds = 2
    const pauseBeforeFadeOutInSeconds = 5

    // style values -- used to associate the style values with animation
    const arrowPaddingLeft = 24
    const arrowPaddingRight = 24
    const containerHorizontalPadding = 24
    const gapInAnimationContainer = 8
    const marginRightInText = 8

    const config = useContext(ConfigurationContext)

    const newsTickerItems = useNewsTicker(config, onEvent)

    // DOM width reference
    const [containerWidth, setContainerWidth] = useState(0)
    const [textWidth, setTextWidth] = useState(0)
    const [classificationWidth, setClassificationWidth] = useState(0)

    const [currentIndex, setCurrentIndex] = useState(-1)
    const [isOverflowing, setIsOverflowing] = useState(false)
    const [animatedTextWidth, setAnimatedFullWidth] = useState(0)

    const containerRef = useCallback((node) => {
        if (node !== null) {
            setContainerWidth(node.getBoundingClientRect().width)
        }
    }, [])

    const textRef = useCallback((node) => {
        if (node !== null) {
            setTextWidth(node.getBoundingClientRect().width)
        }
    }, [])

    const classificationRef = useCallback((node) => {
        if (node !== null) {
            setClassificationWidth(node.getBoundingClientRect().width)
        } else {
            setClassificationWidth(0)
        }
    }, [])

    const handleClick = (
        classification: EditorialType,
        text: string,
        url: string,
        context: CollectionContext,
    ) => {
        onEvent({
            context,
            type: DataLayerEventName.selectItemEvent,
            originator: 'TNNewsTicker',
            payload: {
                index: 0,
                item_brand: 'The Nightly',
                item_category: 'news-ticker',
                ...(classification ? { item_category2: classification } : {}),
                item_name: text,
                price: 0,
                link_text: text,
                link_url: url,
            },
        })
    }

    if (currentIndex < 0) {
        setCurrentIndex(0)
    }

    const animationTime = newsTickerItems
        ? isOverflowing
            ? getTotalTimeForAnimationSequence(
                  fadeDurationInSeconds,
                  pauseBeforeFadeOutInSeconds,
                  animatedTextWidth,
                  speedInPixelsPerSeconds,
                  pauseBeforeScrollLeftInSeconds,
              )
            : getTotalTimeForAnimationSequence(
                  fadeDurationInSeconds,
                  pauseBeforeFadeOutInSeconds,
              )
        : 0

    const animationKeyframes = isOverflowing
        ? getAnimationKeyframes(
              animationTime,
              fadeDurationInSeconds,
              pauseBeforeScrollLeftInSeconds,
              pauseBeforeFadeOutInSeconds,
          )
        : getAnimationKeyframes(
              animationTime,
              fadeDurationInSeconds,
              0,
              pauseBeforeFadeOutInSeconds,
          )

    useLayoutEffect(() => {
        const updateContainerWidth = () => {
            setContainerWidth(window.innerWidth)
        }

        window.addEventListener('resize', updateContainerWidth)
        updateContainerWidth()

        return () => window.removeEventListener('resize', updateContainerWidth)
    }, [])

    useEffect(() => {
        const updateDimensions = () => {
            const scrollableTextWidth = classificationWidth + textWidth

            // calculating the actual width to take into account
            // to decide if the text is overflowing (needs scrolling to left)
            const actualContainerWidth =
                containerWidth -
                arrowPaddingLeft -
                arrowPaddingRight -
                containerHorizontalPadding -
                containerHorizontalPadding // x2 as need to account for both left and right padding

            setIsOverflowing(scrollableTextWidth > actualContainerWidth)

            // animation width should take into account all margins and gaps
            // that the scrolling components have
            let actualAnimatedTextWidth =
                scrollableTextWidth +
                gapInAnimationContainer +
                marginRightInText

            // additional is needed as there is an additional component if
            // classification exists in the scrolling text
            actualAnimatedTextWidth =
                classificationWidth > 0
                    ? actualAnimatedTextWidth + gapInAnimationContainer
                    : actualAnimatedTextWidth

            setAnimatedFullWidth(actualAnimatedTextWidth)
        }

        updateDimensions()
    }, [currentIndex, containerWidth, classificationWidth, textWidth])

    const { newsTickerContext } = getNewsTickerContext(newsTickerItems)

    return newsTickerItems && newsTickerItems.length > 0 ? (
        <StyledContainer
            withGradientBackground={
                newsTickerItems[currentIndex].withGradientBackground
            }
            ref={containerRef}
            isOverflowing={isOverflowing}
        >
            <StyledTextContainer
                horizontalPadding={containerHorizontalPadding}
                to={newsTickerItems[currentIndex].link}
                onClick={() => {
                    handleClick(
                        newsTickerItems[currentIndex].type!,
                        newsTickerItems[currentIndex].text,
                        newsTickerItems[currentIndex].link,
                        newsTickerContext,
                    )
                }}
            >
                <StyledAnimatedTextContainer
                    key={currentIndex}
                    isOverflowing={isOverflowing}
                    fullWidth={animatedTextWidth}
                    playFadeInOut={newsTickerItems.length > 1}
                    animationTime={animationTime}
                    animationKeyframes={animationKeyframes}
                    gapBetweenComponents={gapInAnimationContainer}
                    onAnimationEnd={() => {
                        setCurrentIndex(
                            (prevIndex) =>
                                (prevIndex + 1) % newsTickerItems.length,
                        )
                    }}
                >
                    {newsTickerItems[currentIndex].type && (
                        <StyledClassification
                            type={newsTickerItems[currentIndex].type!}
                            ref={classificationRef}
                        >
                            {newsTickerItems[currentIndex].type ===
                                EditorialType.Live && (
                                <StyledLiveClassificationContainer>
                                    <IconLiveTN />
                                </StyledLiveClassificationContainer>
                            )}
                            {newsTickerItems[currentIndex].type}
                        </StyledClassification>
                    )}

                    <StyledText ref={textRef} marginRight={marginRightInText}>
                        {newsTickerItems[currentIndex].text}
                    </StyledText>
                    {isOverflowing && (
                        <>
                            {newsTickerItems[currentIndex].type && (
                                <StyledClassification
                                    type={newsTickerItems[currentIndex].type!}
                                >
                                    {newsTickerItems[currentIndex].type ===
                                        EditorialType.Live && (
                                        <StyledLiveClassificationContainer>
                                            <IconLiveTN />
                                        </StyledLiveClassificationContainer>
                                    )}
                                    {newsTickerItems[currentIndex].type}
                                </StyledClassification>
                            )}
                            <StyledText marginRight={marginRightInText}>
                                {newsTickerItems[currentIndex].text}
                            </StyledText>
                        </>
                    )}
                    <StyledFloatyArrow isOverflowing={isOverflowing}>
                        <ArrowNarrowRightIcon />
                    </StyledFloatyArrow>
                </StyledAnimatedTextContainer>
            </StyledTextContainer>

            {newsTickerItems[currentIndex].link && (
                <StyledArrowLink
                    to={newsTickerItems[currentIndex].link}
                    onClick={() => {
                        handleClick(
                            newsTickerItems[currentIndex].type!,
                            newsTickerItems[currentIndex].text,
                            newsTickerItems[currentIndex].link,
                            newsTickerContext,
                        )
                    }}
                >
                    <StyledFixedArrow
                        isOverflowing={isOverflowing}
                        animationTime={animationTime}
                        animationKeyframes={animationKeyframes}
                        arrowPaddingLeft={arrowPaddingLeft}
                        arrowPaddingRight={arrowPaddingRight}
                    >
                        <ArrowNarrowRightIcon />
                    </StyledFixedArrow>
                </StyledArrowLink>
            )}
        </StyledContainer>
    ) : (
        <></>
    )
}

export const NewsTicker = ({ ...props }: NewsTickerProps) => {
    const queryClient = new QueryClient()

    const renditionType = useSelector(
        (state: AppState) => state.render.renditionType,
    )
    const location = useLocation()

    const isPuzzlePageAppRendition =
        renditionType === 'app' && isPuzzlePage({ location })

    if (isPuzzlePageAppRendition) {
        return <></>
    }

    return (
        <QueryClientProvider client={queryClient}>
            <InternalNewsTicker {...props} />
        </QueryClientProvider>
    )
}
