// Registrars + Route Builder
import {
    AdUnitRegistration,
    AflFixturesScoreboardWidgetRegistration,
    AflFixturesWidgetRegistration,
    AflInjuriesSuspensionsWidgetRegistration,
    AflMatchLadderWidgetRegistration,
    AflMatchStatsWidgetRegistration,
    AflMatchSummaryWidgetRegistration,
    AflPlayerStatsWidgetRegistration,
    AflScoreBoardRegistration,
    AflSquadsWidgetRegistration,
    AmpGoogleAnalyticsFourRegistration,
    AmpSophiRegistration,
    AmpStickyAdUnitRegistration,
    ArticleHeroRegistration,
    AthenaRegistration,
    BoxRegistration,
    BreachScreenRegistration,
    BreadcrumbRegistration,
    TheNightlyBreadcrumbRegistration,
    BreakingNewsRegistration,
    CerberusFourRegistration,
    CerberusRegistration,
    ComingSoonRegistration,
    ComponentRendererErrorRegistration,
    ContentSeparatorRegistration,
    CookieConsentUserSettingsPageRegistration,
    CoralRegistration,
    CourtInTheActPageRegistration,
    CuratedPromotionalCardRegistration,
    DevelopingNewsRegistration,
    DoubletRegistration,
    ePaperPageLatestEditionRegistration,
    ePaperPageReadersAlsoLoveRegistration,
    ePaperPageRegistration,
    UserSettingRegistration,
    FactCheckerSectionHeaderRegistration,
    FailLoadContent404Registration,
    FailLoadContentRegistration,
    FeatureArticleBreachCompositionRegistration,
    FeatureCompositionRegistration,
    FeatureHeadlineRegistration,
    FederalElectionAccordionRegistration,
    FederalElectionWidgetCollectionRegistration,
    FigCaptionRegistration,
    FlourishIframeRegistration,
    GenericInArticleBannerRegistration,
    GuestBookRegistration,
    HeraRegistration,
    HorizontalCricketWidgetRegistration,
    IframeRegistration,
    ImageBannerRegistration,
    InlineContentRegistration,
    JanusRegistration,
    JulietRegistration,
    LimaRegistration,
    LinkBannerRegistration,
    LiveScoresRegistration,
    LunaRegistration,
    MatchTimelineWidgetRegistration,
    TNNewsletterSignupRegistration,
    NightlySpecificEditionFocusRegistration,
    NovemberRegistration,
    OmnyRegistration,
    OscarRegistration,
    OurPublicationRegistration,
    PaperPromoRegistration,
    ParagraphRegistration,
    PollieRaterBannerRegistration,
    PollieRaterDriverBannerRegistration,
    PollieRaterRegistration,
    PortraitRegistration,
    ProfileCardRegistration,
    ProfileRegistration,
    PromoStrapRegistration,
    PromotionalCardRegistration,
    PublicationRelatedContentRegistration,
    PuzzleIframeRegistration,
    PuzzlesListingRegistration,
    QuickLinksRegistration,
    RedirectorRegistration,
    RegionalsSwgPromoPageRegistration,
    RelatedContentRegistration,
    RippleRegistration,
    SavedArticlesRegistration,
    SearchRegistration,
    SectionHeaderRegistration,
    SharingRegistration,
    SierraRegistration,
    StaticContentRegistration,
    StaticMadMondayPromoCardRegistration,
    StaticSubscribePromoCardRegistration,
    StaticTradingUpPromoCardRegistration,
    StaticUpLatePromoCardRegistration,
    TaboolaRegistration,
    TabRegistration,
    TelethonCountdownRegistration,
    TheWestFeatureRegistration,
    TheWestGalleryRegistration,
    TheWestLiveBillboardRegistration,
    TheWestLivePlayerPageRegistration,
    TheNightlyPageIsUnavailableRegistration,
    TheNightlyPageNotFoundRegistration,
    TheNightlySomethingWentWrongRegistration,
    TheWestTermsContentRegistration,
    TheWestVideoHubRegistration,
    ThorRegistration,
    TimelineFeedWidgetRegistration,
    TopicListRegistration,
    UniformRegistration,
    UpgradeAppRegistration,
    VideoHubRegistration,
    VideoTrailerCardRegistration,
    WebStoriesRegistration,
    WhiskeyRegistration,
    ZeusRegistration,
    TNAppDownloadCardRegistration,
    TNInfoCardRegistration,
    NightlyLatestEditionRegistration,
    NightlyPreviousEditionsRegistration,
    TheNightlyArticleRegistration,
    CardRegistration,
    MarsRegistration,
    TheNightlyThemeConfig,
    getComponentOverriderRegistration,
    getSchemeOverriderRegistration,
    NightlyButtonRegistration,
    SectionContentRegistration,
    TagLinkNavigationRegistration,
    TopicSectionArticleCardListRegistration,
    ArticleCardListRegistration,
    TheNightlyEmailVerifiedRegistration,
    TheNightlySignupSuccessRegistration,
    TNToastNotificationRegistration,
    TNCreateAccountCTARegistration,
    CollectionDataContextRegistration,
    JournoProfileArticleCardListRegistration,
    PaginationRegistration,
    MedalTallyRegistration,
    SearchFiltersRegistration,
    SavedArticlesFiltersRegistration,
    AccountDeletedRegistration,
    TNBreakingNewsRegistration,
    TrendingArticlesRegistration,
} from '@news-mono/component-library'
import {
    componentFeatureToggleMiddleware,
    componentOnEventMiddleware,
    ComponentServices,
    getDataLoaderMiddleware,
    requireEntitlementsMiddleware,
    requireAuthenticationMiddleware,
} from '@news-mono/web-common'
import { LayoutRegistration } from 'json-react-layouts'

// Register Components & Collections
export const layout = LayoutRegistration<ComponentServices>()
    .registerComponents((registrar) =>
        registrar
            .registerComponent(ComponentRendererErrorRegistration)
            .registerComponent(FailLoadContentRegistration)
            .registerComponent(FailLoadContent404Registration)

            // collections
            .registerComponent(JulietRegistration)
            .registerComponent(LimaRegistration)
            .registerComponent(SierraRegistration)
            .registerComponent(UniformRegistration)
            .registerComponent(OscarRegistration)
            .registerComponent(NovemberRegistration)
            .registerComponent(WhiskeyRegistration)
            .registerComponent(PortraitRegistration)

            // components
            // Todo: trim out unnecessary components for TN
            .registerComponent(AdUnitRegistration)
            .registerComponent(AmpStickyAdUnitRegistration)
            .registerComponent(InlineContentRegistration)
            .registerComponent(TheNightlyArticleRegistration)
            .registerComponent(TheWestGalleryRegistration)
            .registerComponent(TheWestFeatureRegistration)
            .registerComponent(StaticContentRegistration)
            .registerComponent(StaticTradingUpPromoCardRegistration)
            .registerComponent(StaticUpLatePromoCardRegistration)
            .registerComponent(StaticMadMondayPromoCardRegistration)
            .registerComponent(SectionHeaderRegistration)
            .registerComponent(BreakingNewsRegistration)
            .registerComponent(PromoStrapRegistration)
            .registerComponent(BreadcrumbRegistration)
            .registerComponent(TheNightlyBreadcrumbRegistration)
            .registerComponent(SavedArticlesRegistration)
            .registerComponent(SearchRegistration)
            .registerComponent(IframeRegistration)
            .registerComponent(PuzzleIframeRegistration)
            .registerComponent(ProfileRegistration)
            .registerComponent(TheNightlyPageNotFoundRegistration)
            .registerComponent(TheNightlySomethingWentWrongRegistration)
            .registerComponent(TheNightlyPageIsUnavailableRegistration)
            .registerComponent(TheNightlyEmailVerifiedRegistration)
            .registerComponent(TheNightlySignupSuccessRegistration)
            .registerComponent(AccountDeletedRegistration)
            .registerComponent(TNToastNotificationRegistration)
            .registerComponent(TNNewsletterSignupRegistration)
            .registerComponent(LinkBannerRegistration)
            .registerComponent(ImageBannerRegistration)
            .registerComponent(PromotionalCardRegistration)
            .registerComponent(ProfileCardRegistration)
            .registerComponent(ArticleHeroRegistration)
            .registerComponent(SharingRegistration)
            .registerComponent(FeatureHeadlineRegistration)
            .registerComponent(FigCaptionRegistration)
            .registerComponent(TopicListRegistration)
            .registerComponent(QuickLinksRegistration)
            .registerComponent(TelethonCountdownRegistration)
            .registerComponent(OmnyRegistration)
            .registerComponent(RelatedContentRegistration)
            .registerComponent(ParagraphRegistration)
            .registerComponent(TaboolaRegistration)
            .registerComponent(RippleRegistration)
            .registerComponent(TheWestLiveBillboardRegistration)
            .registerComponent(PublicationRelatedContentRegistration)
            .registerComponent(ContentSeparatorRegistration)
            .registerComponent(CoralRegistration)
            .registerComponent(TheWestTermsContentRegistration)
            .registerComponent(PaperPromoRegistration)
            .registerComponent(HeraRegistration)
            .registerComponent(RegionalsSwgPromoPageRegistration)
            .registerComponent(PollieRaterRegistration)
            .registerComponent(PollieRaterBannerRegistration)
            .registerComponent(PollieRaterDriverBannerRegistration)
            .registerComponent(RedirectorRegistration)
            .registerComponent(DevelopingNewsRegistration)
            .registerComponent(GuestBookRegistration)
            .registerComponent(ComingSoonRegistration)
            .registerComponent(FlourishIframeRegistration)
            .registerComponent(ePaperPageRegistration)
            .registerComponent(ePaperPageLatestEditionRegistration)
            .registerComponent(ePaperPageReadersAlsoLoveRegistration)
            .registerComponent(MedalTallyRegistration)

            // The Nightly
            .registerComponent(CardRegistration)
            .registerComponent(NightlySpecificEditionFocusRegistration)
            .registerComponent(TNAppDownloadCardRegistration)
            .registerComponent(TNInfoCardRegistration)
            .registerComponent(NightlyLatestEditionRegistration)
            .registerComponent(NightlyPreviousEditionsRegistration)
            .registerComponent(NightlyButtonRegistration)
            .registerComponent(ArticleCardListRegistration)
            .registerComponent(TNCreateAccountCTARegistration)
            .registerComponent(TopicSectionArticleCardListRegistration)
            .registerComponent(JournoProfileArticleCardListRegistration)
            .registerComponent(PaginationRegistration)
            .registerComponent(TNBreakingNewsRegistration)
            .registerComponent(TrendingArticlesRegistration)

            //afl match centre
            .registerComponent(AflFixturesWidgetRegistration)
            .registerComponent(AflFixturesScoreboardWidgetRegistration)
            .registerComponent(AflInjuriesSuspensionsWidgetRegistration)
            .registerComponent(AflMatchLadderWidgetRegistration)
            .registerComponent(AflMatchStatsWidgetRegistration)
            .registerComponent(AflPlayerStatsWidgetRegistration)
            .registerComponent(AflScoreBoardRegistration)
            .registerComponent(AflSquadsWidgetRegistration)
            .registerComponent(MatchTimelineWidgetRegistration)
            .registerComponent(AflMatchSummaryWidgetRegistration)
            .registerComponent(TimelineFeedWidgetRegistration)
            .registerComponent(UserSettingRegistration)

            // internal west components
            .registerComponent(HorizontalCricketWidgetRegistration)
            .registerComponent(LiveScoresRegistration)
            .registerComponent(StaticSubscribePromoCardRegistration)
            .registerComponent(VideoTrailerCardRegistration)
            .registerComponent(OurPublicationRegistration)
            .registerComponent(TheWestLivePlayerPageRegistration)
            .registerComponent(CookieConsentUserSettingsPageRegistration)
            .registerComponent(FactCheckerSectionHeaderRegistration)
            .registerComponent(FederalElectionWidgetCollectionRegistration)
            .registerComponent(GenericInArticleBannerRegistration)
            .registerComponent(VideoHubRegistration)
            .registerComponent(TheWestVideoHubRegistration)
            .registerComponent(CourtInTheActPageRegistration)

            .registerComponent(UpgradeAppRegistration)
            .registerComponent(AmpSophiRegistration)
            .registerComponent(AmpGoogleAnalyticsFourRegistration)

            .registerComponent(PuzzlesListingRegistration)
            .registerComponent(BreachScreenRegistration)
            .registerComponent(CuratedPromotionalCardRegistration)
            .registerComponent(FederalElectionAccordionRegistration)
            .registerComponent(WebStoriesRegistration)

            .registerMiddleware(componentFeatureToggleMiddleware)
            .registerMiddleware(getDataLoaderMiddleware('component'))
            .registerMiddleware(componentOnEventMiddleware)
            .registerMiddleware(requireEntitlementsMiddleware)
            .registerMiddleware(requireAuthenticationMiddleware),
    )
    .registerCompositions((registrar) =>
        registrar
            .registerComposition(AthenaRegistration)
            .registerComposition(ZeusRegistration)
            .registerComposition(ThorRegistration)
            .registerComposition(BoxRegistration)
            .registerComposition(CerberusRegistration)
            .registerComposition(CerberusFourRegistration)
            .registerComposition(JanusRegistration)
            .registerComposition(TabRegistration)
            .registerComposition(DoubletRegistration)
            .registerComposition(FeatureCompositionRegistration)
            .registerComposition(FeatureArticleBreachCompositionRegistration)
            .registerComposition(LunaRegistration)
            .registerComposition(SectionContentRegistration)
            .registerComposition(MarsRegistration)
            .registerComposition(CollectionDataContextRegistration)
            .registerComposition(
                getComponentOverriderRegistration<TheNightlyThemeConfig>(),
            )
            .registerComposition(
                getSchemeOverriderRegistration<TheNightlyThemeConfig>(),
            )
            .registerComposition(TagLinkNavigationRegistration)
            .registerComposition(SearchFiltersRegistration)
            .registerComposition(SavedArticlesFiltersRegistration)
            .registerMiddleware(componentFeatureToggleMiddleware)
            .registerMiddleware(getDataLoaderMiddleware('composition'))
            .registerMiddleware(componentOnEventMiddleware)
            .registerMiddleware(requireEntitlementsMiddleware)
            .registerMiddleware(requireAuthenticationMiddleware),
    )
