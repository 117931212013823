import React from 'react'
import { ErrorPage } from '../ErrorPage'
import { StyledH1, StyledH2 } from '../ErrorPage.styled'
import { PageError } from 'libs/web-common/src/events'

type TheNightlySomethingWentWrongProps = {
    error: PageError
}

export const TheNightlySomethingWentWrong = ({
    error,
}: TheNightlySomethingWentWrongProps) => {
    return (
        <ErrorPage errorId={error.errorId}>
            <StyledH1>What. A. Nightmare.</StyledH1>
            <StyledH2>{error.errorType} Error</StyledH2>
            <p>
                Breaking news: Like a rogue reporter, this page has gone AWOL.
            </p>
            <p>
                We’re onto it, but you might need to refresh your browser or
                come back later.
            </p>
        </ErrorPage>
    )
}
