import {
    createRegisterableComponentWithData,
    CurationAndCurationMetadataLoader,
} from '@news-mono/web-common'
import { TNBreakingNews } from '@news-mono/component-library'
import React from 'react'
import { TrendingArticles } from './TrendingArticles'

export const TrendingArticlesRegistration = createRegisterableComponentWithData(
    'trending-articles',
    CurationAndCurationMetadataLoader,
    (_, data, { onEvent }) => {
        if (data.loaded && data.result.articles.length > 0) {
            const articles = data.result.articles

            return <TrendingArticles articles={articles} onEvent={onEvent} />
        }
        return null
    },
)
