import { AllEvents, PublicationCardItem } from '@news-mono/web-common'
import React, { FC } from 'react'
import {
    StyledTrendingArticlesArticle,
    StyledTrendingArticlesContainer,
    StyledTrendingArticlesGrid,
    StyledTrendingArticlesHeader,
    StyledTrendingArticlesHeaderIcon,
    StyledTrendingArticlesHeaderText,
} from './TrendingArticles.styled'
import { TrendingNewsIcon } from './assets/TrendingNewsIcon'
import { LandscapeNightly } from '../../cards/LandscapeNightly/LandscapeNightly'

interface TrendingArticlesProps {
    articles: PublicationCardItem[]
    onEvent: (event: AllEvents) => void
}

export const TrendingArticles: FC<TrendingArticlesProps> = ({
    articles,
    onEvent,
}) => {
    return (
        <StyledTrendingArticlesContainer>
            <StyledTrendingArticlesHeader>
                <StyledTrendingArticlesHeaderIcon>
                    <TrendingNewsIcon />
                </StyledTrendingArticlesHeaderIcon>
                <StyledTrendingArticlesHeaderText>
                    Trending right now
                </StyledTrendingArticlesHeaderText>
            </StyledTrendingArticlesHeader>
            <StyledTrendingArticlesGrid>
                {articles.map((article, i) => (
                    <StyledTrendingArticlesArticle key={article.id}>
                        <LandscapeNightly
                            item={{
                                loaded: true,
                                result: article,
                            }}
                            onEvent={onEvent}
                            cardNumber={i + 1}
                            contentPosition="center"
                            displayNumber={false}
                            imagePosition="left"
                            hideByline={false}
                        />
                    </StyledTrendingArticlesArticle>
                ))}
            </StyledTrendingArticlesGrid>
        </StyledTrendingArticlesContainer>
    )
}
