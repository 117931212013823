import {
    AdUnitWrapper,
    TNFlyoutNav,
    StyledStickyFooterAdUnit,
    TheNightlyHeaderActions,
    TheNightlyStickySiteHeader,
    TNFooter,
    TNCreateAccountBreachModal,
    FeatureToggle,
    CleanupTruskin,
    ProviderChildProps,
    pageContainerId,
} from '@news-mono/component-library'
import { PageProps } from 'page-lifecycle-provider'
import React, { ReactNode } from 'react'
import version from '../../server/version'
import { footerLinks } from '../footer'
import {
    getCommonNavItems,
    getLatestNavItems,
    myAccountNavItems,
    quickLinks,
    sectionNavItems,
} from '../navigation'
import { BaseTheNightlyProps } from './BaseTheNightly'
import { PageContent, PageWrapper } from './BaseTheNightly.styled'
import {
    AppState,
    isPuzzlePage,
    isPathToPageContainingFilteredList,
    NavEvent,
    BannerEvent,
    isSavedArticlesPage,
    useFeature,
    CollectionEvent,
} from '@news-mono/web-common'
import { useSelector } from 'react-redux'

export interface Props {
    headerAd?: (rendered?: () => void) => JSX.Element | undefined
    primaryNav: JSX.Element
    pageType: string
    flyoutNav: JSX.Element
    content: JSX.Element
    footer: ReactNode
    footerAd?: JSX.Element
    hasVerticalGutter?: boolean
    onEvent: (event: NavEvent | CollectionEvent | BannerEvent) => void
    flyOutProps?: ProviderChildProps
}

export const BaseTheNightlyWebTemplate: React.FC<Props> = ({
    headerAd,
    onEvent,
    primaryNav,
    flyoutNav,
    content,
    footer,
    footerAd,
    hasVerticalGutter,
    pageType,
    flyOutProps,
}) => {
    return (
        <PageWrapper className="js-content-area">
            {flyoutNav}
            <TheNightlyStickySiteHeader
                headerAd={headerAd && headerAd}
                navigation={primaryNav}
                onEvent={onEvent}
                quickLinks={quickLinks}
                flyOutProps={flyOutProps}
            />
            <PageContent
                hasVerticalGutter={hasVerticalGutter}
                id={pageContainerId}
            >
                {content}
            </PageContent>
            {footer}
            {footerAd}
            <TNCreateAccountBreachModal pageType={pageType} />
        </PageWrapper>
    )
}
BaseTheNightlyWebTemplate.displayName = 'BaseTheNightlyWebTemplate'

export const BaseTheNightlyWeb: React.FC<BaseTheNightlyProps> = ({
    additionalPageProperties,
    ads,
    onEvent,
    pageType,
    pageContents,
    section,
    location,
    flyOutProps,
}) => {
    const inApp = useSelector(
        (state: AppState) => state.render.renditionType === 'app',
    )

    const isAppPuzzlesPage = inApp && isPuzzlePage({ location })
    const isAppSavedArticlesPage =
        useFeature('custom-saved-articles-app-ui') &&
        inApp &&
        isSavedArticlesPage({ location })
    const pageContainsFilteredList = isPathToPageContainingFilteredList({
        location,
    })
    const isHome = location.pathname === '/'

    return (
        <>
            <BaseTheNightlyWebTemplate
                onEvent={onEvent}
                pageType={pageType}
                flyOutProps={flyOutProps}
                headerAd={(rendered) =>
                    ads && (
                        <AdUnitWrapper
                            noticePosition="above-center"
                            padding={[4, 0, 0, 0]}
                            adState={ads}
                            unitId="header"
                            unitRendered={rendered}
                            onEvent={onEvent}
                            adType={'inline'}
                        />
                    )
                }
                flyoutNav={
                    <TNFlyoutNav
                        navLinks={{
                            latest: getLatestNavItems(inApp),
                            'my-account': myAccountNavItems,
                            'the-nightly': getCommonNavItems(inApp),
                            section: sectionNavItems,
                        }}
                        mainNavOpen={flyOutProps.mainNavOpen}
                        closeNavigation={flyOutProps.closeNavigation}
                        onEvent={onEvent}
                    />
                }
                primaryNav={
                    isAppPuzzlesPage || isAppSavedArticlesPage ? (
                        <></>
                    ) : (
                        <TheNightlyHeaderActions
                            mainNavOpen={flyOutProps.mainNavOpen}
                            openNavigation={flyOutProps.openNavigation}
                            closeNavigation={flyOutProps.closeNavigation}
                            onEvent={onEvent}
                            isHome={isHome}
                            location={location}
                        />
                    )
                }
                content={
                    <>
                        {pageContents}
                        <PageProps
                            pageProperties={{
                                ...additionalPageProperties,
                                section,
                                pageType,
                                codebase: {
                                    version: version.buildNumber,
                                    sha: version.sha,
                                },
                            }}
                        ></PageProps>
                    </>
                }
                footer={<TNFooter links={footerLinks} />}
                footerAd={
                    isAppPuzzlesPage ? (
                        <></>
                    ) : (
                        ads && (
                            <StyledStickyFooterAdUnit>
                                <AdUnitWrapper
                                    noticePosition="none"
                                    padding={[4, 0]}
                                    adState={ads}
                                    unitId="sticky-footer"
                                    onEvent={onEvent}
                                    adType={'inline'}
                                />
                            </StyledStickyFooterAdUnit>
                        )
                    )
                }
                hasVerticalGutter={
                    !isAppPuzzlesPage &&
                    !pageContainsFilteredList &&
                    !isHome &&
                    additionalPageProperties.hasVerticalGutter
                }
            />
            <FeatureToggle
                feature={'bonzai-truskin'}
                on={() =>
                    ads ? (
                        <>
                            <CleanupTruskin />
                            <AdUnitWrapper
                                noticePosition="none"
                                adState={ads}
                                unitId="truskin"
                                onEvent={onEvent}
                                adType={'inline'}
                            />
                        </>
                    ) : null
                }
            />
        </>
    )
}
BaseTheNightlyWeb.displayName = 'BaseTheNightlyWeb'
